import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';

import Title from '../../components/Title';
import { getRegions } from "../../services/regions";

export default function RegionList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [recordCount, setRecordCount] = useState(0);
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        getRegions({
            name: getSearchParam('name') || '',
            page: getSearchParam('currentPage') || 0,
            sort: `${getSearchParam('sort') || 'name'},${getSearchParam('sortOrder') || 'asc'}`,
            size: getSearchParam('size') || 5
        }).then((res) => {
            setRegions(res.data.content);
            setRecordCount(res.data.totalElements);
            setLoading(false);
        });
    }, [searchParams]);

    const sortField = (field) => {
        if (field !== getSearchParam('sort')) {
            updateSearch('sort', field);
            updateSearch('sortOrder', 'asc');
        } else {
            updateSearch('sortOrder', getSearchParam('sortOrder') === "asc" ? "desc" : "asc");
        }
    };

    const handleChangePage = (event, newPage) => {
        updateSearch('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        updateSearch('size', +event.target.value);
        updateSearch('currentPage', 0);
    };

    const updateSearch = (field, value) => {
        setSearchParams(d => {
            d.set('reg.' + field, value);
            return d;
        })
    };

    const getSearchParam = (field) => searchParams.get('reg.' + field);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Title>Regions</Title>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                            <Button startIcon={<AddCircleIcon />} component={Link} to={"/regions/region"}>
                                Add Region
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField label="Search Name"
                        InputProps={{
                            startAdornment: <SearchIcon />,
                            endAdornment: getSearchParam('name') && (
                                <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => updateSearch('name', '')} />
                            )
                        }}
                        value={getSearchParam('name') || ''}
                        onChange={(e) => {
                            updateSearch('currentPage', 0);
                            updateSearch('name', e.target.value.toUpperCase());
                        }}
                        fullWidth
                        variant="standard" />
                </Grid>
            </Grid>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={getSearchParam('sort') === 'name' ? getSearchParam('sortOrder') : false }>
                                <TableSortLabel active={getSearchParam('sort') === 'name'}
                                    direction={getSearchParam('sort') === 'name' ? getSearchParam('sortOrder') : 'asc'}
                                    onClick={() => sortField('name')}>
                                    Name
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                    <CircularProgress color="inherit" />
                                </TableCell>
                            </TableRow>
                            :
                            regions.map((region, idx) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={region.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate(`/regions/view/${region.id}`)}>
                                        <TableCell>
                                            <Typography>{region.name}</Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={recordCount}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                rowsPerPage={parseInt(getSearchParam('size') || 5)}
                page={recordCount > 0 ? parseInt(getSearchParam('currentPage') || 0) : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </>
    );
}
