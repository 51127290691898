import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from '@mui/material/Avatar';

import Title from '../../../components/Title';
import { stringAvatar, } from "../../../helpers/string-utils";
import { getDocumentsSummary } from '../../../services/employment-documents';

const employeeNameSortField = 'employee.lastName,employee.firstName,employee.suffix,employee.middleName';

export default function EmploymentDocumentList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [recordCount, setRecordCount] = useState(0);
    const [employmentDocsSummary, setEmploymentDocsSummary] = useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const sortBy = searchParams.get('sort') || employeeNameSortField;
        const sortOrder = searchParams.get('sortOrder') || 'asc';

        if (!searchParams.get('sort')) {
            setSearchParams(sp => {
                sp.set('sort', sortBy);
                sp.set('sortOrder', sortOrder);
                return sp;
            });
            return; // this effect will fire again
        }

        getDocumentsSummary({
            name: searchParams.get('name') || '',
            page: searchParams.get('currentPage') || 0,
            size: searchParams.get('size') || 25,
            sort: searchParams.get('sort') + ',' + searchParams.get('sortOrder'),
        }).then(res => {
            setLoading(false);
            setRecordCount(res.data.totalElements);
            setEmploymentDocsSummary(res.data?.content);
        });
    }, [searchParams]);

    const sortField = (field) => {
        if (field !== searchParams.get('sort')) {
            updateSearch('sort', field);
            updateSearch('sortOrder', 'asc');
        } else {
            updateSearch('sortOrder', searchParams.get('sortOrder') === "asc" ? "desc" : "asc");
        }
    };

    const handleChangePage = (event, newPage) => {
        updateSearch('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        updateSearch('size', +event.target.value);
        updateSearch('currentPage', 0);
    };

    const updateSearch = (field, value) => {
        setSearchParams(d => {
            d.set(field, value);
            return d;
        })
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Title>Performance Management</Title>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                            <Button startIcon={<AddCircleIcon />} component={Link} to={"/transactions/performance/add-new"}>
                                Upload Document
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField label="Search Name"
                        InputProps={{
                            startAdornment: <SearchIcon />,
                            endAdornment: searchParams.get('name') && (
                                <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => updateSearch('name', '')} />
                            )
                        }}
                        value={searchParams.get('name') || ''}
                        onChange={(e) => {
                            updateSearch('currentPage', 0);
                            updateSearch('name', e.target.value.toUpperCase());
                        }}
                        fullWidth
                        variant="standard" />
                </Grid>
            </Grid>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell width={50}></TableCell>
                            <TableCell sortDirection={searchParams.get('sort') === employeeNameSortField ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === employeeNameSortField}
                                    direction={searchParams.get('sort') === employeeNameSortField ? searchParams.get('sortOrder') : 'asc'}
                                    onClick={() => sortField(employeeNameSortField)}>
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={140} style={{textAlign: 'right'}}>Memo</TableCell>
                            <TableCell width={160} style={{textAlign: 'right'}}>Preventive Suspension</TableCell>
                            <TableCell width={200} style={{textAlign: 'right'}}>Administrative Hearing Notification</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                    <CircularProgress color="inherit" />
                                </TableCell>
                            </TableRow>
                            :
                            employmentDocsSummary.map((docSummary) => (
                                <TableRow hover tabIndex={-1} key={docSummary.employeeId}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => navigate(`/transactions/performance/${docSummary.employeeId}`)}>
                                    <TableCell>
                                        {docSummary.hasPhoto ?
                                            <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${docSummary.employeeId}?rnd=${new Date().getTime()}`} />
                                            :
                                            <Avatar {...stringAvatar(`${docSummary.fullName}`)} />
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {docSummary.fullName}
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>{docSummary.noOfMemos ?? 0}</TableCell>
                                    <TableCell style={{textAlign: 'right'}}>{docSummary.noOfSuspensions ?? 0}</TableCell>
                                    <TableCell style={{textAlign: 'right'}}>{docSummary.noOfAdminHearingNotices ?? 0}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination component="div"
                count={recordCount}
                rowsPerPage={parseInt(searchParams.get('size') || 25)}
                page={recordCount > 0 ? parseInt(searchParams.get('currentPage') || 0) : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </React.Fragment>
    );
}
