import React from 'react';
import { Link } from 'react-router-dom';

import BadgeIcon from '@mui/icons-material/Badge';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Divider from '@mui/material/Divider';
import DomainIcon from '@mui/icons-material/Domain';
import Groups2Icon from '@mui/icons-material/Groups2';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Tooltip from '@mui/material/Tooltip';
import { ReceiptLong } from '@mui/icons-material';

export const mainListItems = (
    <React.Fragment>
        <Tooltip title="Dashboard" placement="right">
            <ListItemButton component={Link} to={"/home"}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
        </Tooltip>
        <Divider />
        <Tooltip title="Departments" placement="right">
            <ListItemButton component={Link} to={"/departments"}>
                <ListItemIcon>
                    <Groups2Icon />
                </ListItemIcon>
                <ListItemText primary="Departments" />
            </ListItemButton>
        </Tooltip>
        <Tooltip title="Branches" placement="right">
            <ListItemButton component={Link} to={"/branches"}>
                <ListItemIcon>
                    <DomainIcon />
                </ListItemIcon>
                <ListItemText primary="Branches" />
            </ListItemButton>
        </Tooltip>
        <Divider />
        <Tooltip title="Positions" placement="right">
            <ListItemButton component={Link} to={"/positions"}>
                <ListItemIcon>
                    <BadgeIcon />
                </ListItemIcon>
                <ListItemText primary="Positions" />
            </ListItemButton>
        </Tooltip>
        <Divider />
        <Tooltip title="Transactions" placement="right">
            <ListItemButton component={Link} to={"/transactions"}>
                <ListItemIcon>
                    <ReceiptLong />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
            </ListItemButton>
        </Tooltip>
  </React.Fragment>
);

export const adminListItems = (
    <React.Fragment>
        <Tooltip title="Users" placement="right">
            <ListItemButton component={Link} to={"/users"}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>
        </Tooltip>
    </React.Fragment>
);

export const userListItems = (
    <React.Fragment>
        <Tooltip title="Logout" placement="right">
            <ListItemButton component={Link} to={"/logout"}>
                <ListItemIcon>
                    <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </Tooltip>
    </React.Fragment>
);
