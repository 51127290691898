import React, { useLayoutEffect, useState } from "react";

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { mergeJSON } from '../../../../helpers/json-utils';

const emptyData = {
    sssNo: '',
    philHealthNo: '',
    hdmfNo: '',
    tin: '',
}

export default function RemittanceData({ data, updateEmployee, readOnly }) {
    const [remittanceData, setRemittanceData] = useState(emptyData);

    useLayoutEffect(() => {
        setRemittanceData(mergeJSON(emptyData, data));
    }, [data]);

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setRemittanceData(b => ({
            ...b,
            ...dirty
        }));
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="sssNo"
                    label="SSS No."
                    value={remittanceData.sssNo}
                    onChange={(e) => updateField('sssNo', e.target.value)}
                    onBlur={(e) => updateEmployee('remittanceData', remittanceData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={9}>
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="philHealthNo"
                    label="PhilHealth No."
                    value={remittanceData.philHealthNo}
                    onChange={(e) => updateField('philHealthNo', e.target.value)}
                    onBlur={(e) => updateEmployee('remittanceData', remittanceData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={9}>
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="hdmfNo"
                    label="HDMF No."
                    value={remittanceData.hdmfNo}
                    onChange={(e) => updateField('hdmfNo', e.target.value)}
                    onBlur={(e) => updateEmployee('remittanceData', remittanceData)}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item sm={9}>
            </Grid>
            <Grid item sm={3}>
                <TextField InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    id="tin"
                    label="TIN"
                    value={remittanceData.tin}
                    onChange={(e) => updateField('tin', e.target.value)}
                    onBlur={(e) => updateEmployee('remittanceData', remittanceData)}
                    fullWidth
                    variant="standard" />
            </Grid>
        </Grid>
    );
}