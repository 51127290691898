import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState } from 'react';

/**
 * Renders a tabbed component.
 *
 * Markup:
 * ```html
 * <TabbedPane>
 *     <TabbedPane.Tab title="Tab 1">
 *         Tab 1 content goes here...
 *     </TabbedPane.Tab>
 *     <TabbedPane.Tab title="Tab 2">
 *         Tab 2 content goes here...
 *     </TabbedPane.Tab>
 * </TabbedPane>
 * ```
 *
 * @param {number} selectedTab
 * @param props
 * @return {Element}
 * @constructor
 */
export default function TabbedPane({ selectedTab = 0, ...props }) {

    const [activeTab, setActiveTab] = useState(selectedTab);
    const tabs = React.Children.toArray(props.children)?.filter(c => React.isValidElement(c) && c.type === TabbedPane.Tab);

    return <Box {...props}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs aria-label="basic tabs example"
                  value={activeTab}
                  onChange={(e, val) => setActiveTab(val)}
                  variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                {tabs?.map((content, index) =>
                    <Tab key={index}
                         label={content.props.title ?? `Tab ${index + 1}`}
                         style={content.props.style}
                         disabled={content.props.disabled}
                    />
                )}
            </Tabs>
        </Box>
        {tabs?.map((content, index) =>
            <div key={index} role="tabpanel"
                 hidden={activeTab !== index}
                 {...content.props}>
                <Box sx={{ p: 3 }}>{content}</Box>
            </div>
        )}
    </Box>;
}

TabbedPane.Tab = ({ title, style, disabled, ...props }) => <>{props.children}</>;