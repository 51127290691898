import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PhoneIcon from '@mui/icons-material/Phone';
import Link2 from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

import Title from '../../components/Title';
import { getAllRegions, } from '../../services/regions';
import { getDivision, saveDivision } from "../../services/divisions";
import { mergeJSON } from '../../helpers/json-utils';
import { stringAvatar, } from "../../helpers/string-utils";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getAllDepartments } from '../../services/depratments';

const emptyData = {
    id: '',
    name: '',
    regions: [],
    departments: [],
    type: '',
};

export default function Division() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [originalData, setOriginalData] = useState(emptyData);
    const [division, setDivision] = useState(emptyData);
    const [departments, setDepartments] = useState([]);
    const [regions, setRegions] = useState([]);
    const [readOnly, setReadOnly] = useState(false);

    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) return;

        setLoading(true);
        getDivision({ id }).then((res) => {
            const data = mergeJSON(emptyData, res.data);
            setDivision(data);
            setOriginalData(data);
            setReadOnly(true);
            setLoading(false);
        });
    }, [id]);

    useLayoutEffect(() => {
        getAllRegions({ sort: 'name' }).then(res => setRegions(res.data));
    }, []);

    useLayoutEffect(() => {
        getAllDepartments({ sort: 'name' }).then(res => setDepartments(res.data));
    }, []);

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const save = () => {
        setLoading(true);
        saveDivision({
            division: division
        }).then((res) => {
            setLoading(false);
            setDivision(res.data);
            setOriginalData(res.data);
            popUpMessage(true, 'Successfully saved division details.');
            setReadOnly(true);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setDivision(b => ({
            ...b,
            ...dirty
        }));
    };

    const cancel = async () => {
        setDivision(originalData);
        setReadOnly(true);
    }

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Title>Division Details</Title>
            <Box sx={{ mt: 5, minHeight: '60vh' }} component="form">
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="name"
                            label="Name"
                            value={division.name}
                            onChange={(e) => updateField('name', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="type-label" shrink={true}>Type</InputLabel>
                        <Select labelId="type-label"
                                id="type"
                                required
                                fullWidth
                                readOnly={readOnly}
                                disableUnderline={readOnly}
                                value={division.type}
                                style={{textAlign: 'left'}}
                                onChange={(e) => updateField('type', e.target.value)}
                                onBlur={(e) => updateField('type', e.target.value)}>
                          <MenuItem key="ADMIN" value="ADMIN">Admin</MenuItem>
                          <MenuItem key="OPERATION" value="OPERATION">Operation</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {!readOnly && division.type === 'ADMIN' &&
                        <Grid item sm={12}>
                            <Autocomplete
                                readOnly={readOnly}
                                multiple
                                options={departments}
                                getOptionLabel={(option) => option.name}
                                value={division.departments}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                        variant="standard"
                                        label="Departments" />
                                )}
                                onChange={(e, value) => updateField("departments", value)} />
                        </Grid>
                    }
                    {!readOnly && division.type === 'OPERATION' &&
                        <Grid item sm={12}>
                            <Autocomplete
                                readOnly={readOnly}
                                multiple
                                options={regions}
                                getOptionLabel={(option) => option.name}
                                value={division.regions}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                        variant="standard"
                                        label="Regions" />
                                )}
                                onChange={(e, value) => updateField("regions", value)} />
                        </Grid>
                    }
                </Grid>
                {readOnly && division.id &&
                    <Box>
                        <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                            Manager
                        </Typography>
                        <TableContainer>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={50}></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell width={"25%"}>Start Date</TableCell>
                                        <TableCell width={"12%"}>Contact #</TableCell>
                                        <TableCell width={"18%"}>Email</TableCell>
                                        <TableCell width={"12%"}>Birth Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {division.managerDataList?.length > 0 ?
                                        division.managerDataList.map((manager, idx) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={`manager-${manager.id}`}
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => navigate(`/transactions/employees/${manager.id}`)}>
                                                    <TableCell width={50}>
                                                            {manager.hasPhoto ?
                                                                <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${manager.id}?rnd=${new Date().getTime()}`} />
                                                                :
                                                                <Avatar {...stringAvatar(`${manager.personalData.lastName},${[manager.personalData.firstName, manager.personalData.suffix].filter(a => !!a).join(' ')}`)} />
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {manager.personalData.lastName}, {[manager.personalData.firstName, manager.personalData.suffix].filter(a => !!a).join(' ')} {manager.personalData.middleName?.charAt(0)}
                                                            </Typography>
                                                            {manager.employmentInfoData &&
                                                                <Typography variant="caption">
                                                                    {manager.employmentInfoData.positionData?.shortName || manager.employmentInfoData.positionData?.name} - {manager.employmentInfoData.type}
                                                                </Typography>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "25%",
                                                            minWidth: 350,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {manager.employmentInfoData?.startDate} - {moment(manager.employmentInfoData?.startDate, 'MM/DD/YYYY').fromNow(true)}
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "12%",
                                                            minWidth: 150,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {!(!manager.personalData.contactNumber) &&
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <PhoneIcon />
                                                                    <Link2 href={`tel:${manager.personalData.contactNumber}`}
                                                                        underline="hover"
                                                                        onClick={(e) => e.stopPropagation()}>
                                                                        {manager.personalData.contactNumber}
                                                                    </Link2>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "18%",
                                                            minWidth: 250,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {!(!manager.personalData.email) &&
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <EmailIcon />
                                                                    <Link2 href={`mailto:${manager.personalData.email}`}
                                                                        underline="hover"
                                                                        onClick={(e) => e.stopPropagation()}>{manager.personalData.email}</Link2>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "12%",
                                                            minWidth: 150,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {!(!manager.personalData.birthDate) &&
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <CakeIcon />
                                                                    <Typography variant="body2">{manager.personalData.birthDate}</Typography>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                                                No manager found
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {division.type === 'ADMIN' &&
                            <React.Fragment>
                                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                                  Departments
                                </Typography>
                                <TableContainer>
                                    <Table stickyHeader={true}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {division.departments?.length > 0 ?
                                                division.departments.map((departments) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={departments.id}
                                                                  sx={{ cursor: 'pointer' }}
                                                                  onClick={() => navigate(`/departments/view/${departments.id}`)}>
                                                            <TableCell>
                                                                <Typography>{departments.name}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                                        No records found
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>
                        }
                        {division.type === 'OPERATION' &&
                            <React.Fragment>
                                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                                    Regions
                                </Typography>
                                <TableContainer>
                                    <Table stickyHeader={true}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {division.regions?.length > 0 ?
                                                division.regions.map((region) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={region.id}
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => navigate(`/regions/view/${region.id}`)}>
                                                            <TableCell>
                                                                <Typography>{region.name}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                                        No records found
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>
                        }
                    </Box>
                }
            </Box>

            <Dialog open={openMessage}>
                <DialogTitle id="alert-dialog-title">
                    {success ? "Success": "Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMessage(!openMessage)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                    {!readOnly ?
                        <React.Fragment>
                            <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            {!!id &&
                                <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                    Cancel
                                </Button>
                            }
                        </React.Fragment>
                    :
                        <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Edit
                        </Button>
                    }
                </Box>
            </AppBar>
        </Paper>
    );
}
