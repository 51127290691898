import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Title from '../../../components/Title';
import TabbedPane from '../../../components/TabbedPane';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EmployeeNameLookup from '../../../components/EmployeeNameLookup';
import { getEmployee } from '../../../services/employees';
import { generatePublicLink, getDocumentsForEmployee, uploadDocument } from '../../../services/employment-documents';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MessageDialog from '../../../components/MessageDialog';
import { isFileSizeAllowed } from '../../../helpers/file-utils';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const docTypes = [
    ['MEMO', 'Memos'],
    ['SUSPENSION', 'Preventive Suspension'],
    ['ADMIN_HEARING_NOTICE', 'Administrative Hearing Notification'],
]

export default function EmploymentDocument() {
    const { id: viewIdParam } = useParams();
    const [id] = useState(viewIdParam && viewIdParam !== 'add-new' ? viewIdParam : undefined);
    const navigate = useNavigate();
    const [employee, setEmployee] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [popupMessage, setPopupMessage] = useState(null);

    useLayoutEffect(() => {
        onInit();
    }, [id]);

    async function onInit() {
        if (!id) return;

        setLoading(true);
        setReadOnly(true)
        await Promise.all([
            getEmployee({ id }).then(res => setEmployee(res.data)),
            loadDocuments(id),
        ]);
        setLoading(false);
    }

    function loadDocuments(employeeId) {
        return getDocumentsForEmployee(employeeId)
            .then(res => setDocuments([...res.data]))
    }

    function onEmployeeChange(value) {
        setEmployee(value);
        if (value) {
            setLoading(true);
            loadDocuments(value.id)
                .finally(() => setLoading(false));
        } else {
            setDocuments([]);
        }
    }

    function onChangeFileInput(event, type) {
        const [file] = event.target.files;
        if (!isFileSizeAllowed(file)) {
            popUpMessage(false, "File must be less than 10MB.");
            event.target.value = "";
            return;
        }

        let data = new FormData();
        data.append('file', file, file.name);
        data.append('empId', employee.id);
        data.append('type', type);

        setLoading(true);
        uploadDocument(data)
            .then(() => {
                setLoading(false);
                popUpMessage(true, 'Document successfully uploaded.');
                loadDocuments(employee.id);
            })
            .catch((err) => {
                setLoading(false);
                popUpMessage(false, err.response.data);
            })
            .finally(() => event.target.value = "");
    }

    function popUpMessage(isOk, message) {
        setPopupMessage({
            title: isOk ? 'Success' : 'Error',
            message: message?.toString(),
        });
    }

    function download(id) {
        setLoading(true);
        generatePublicLink(id)
            .then(res => window.open(res.data, '_blank'))
            .finally(() => setLoading(false));
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="inherit"/>
                </IconButton>
            </Stack>
            <Title>Performance Management Documents</Title>
            <Box sx={{ mt: 5 }} style={{ paddingBottom: 20 }} component="form" noValidate autoComplete="off">
                <Grid item sm={6} xs={12}>
                    <EmployeeNameLookup
                        readOnly={readOnly}
                        value={employee}
                        onChange={onEmployeeChange}
                    />
                </Grid>
            </Box>

            <TabbedPane>
                {docTypes.map(([type, label]) => (
                    <TabbedPane.Tab key={type} title={label} style={{color: type === 'SOA' ? 'darkred' : ''}} disabled={!employee?.id}>
                        {employee && (
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Documents</TableCell>
                                            <TableCell width={50}>
                                                {employee?.id &&
                                                    <Button variant="contained" component="label" title="Upload document">
                                                        <AddIcon/>
                                                        Add
                                                        <input type="file" hidden onChange={e => onChangeFileInput(e, type)}/>
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documents?.filter(d => d.type === type).map((attachment, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {attachment.fileName}
                                                    <div><small>Uploaded
                                                        by {attachment.createdBy} on {attachment.createDate}</small></div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton variant="contained" component="label"
                                                                onClick={() => download(attachment.id)}>
                                                        <FileDownloadIcon fontSize="inherit"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </TabbedPane.Tab>
                ))}
            </TabbedPane>

            <MessageDialog
                title={popupMessage?.title}
                message={popupMessage?.message}
                open={!!popupMessage}
                onClose={() => setPopupMessage(null)}
            />

            <LoadingSpinner open={loading}/>
        </LocalizationProvider>
    );
}
