import api from "./api";

export const getAdminOrders = ({name = '', employeeId = '', page = 0, size = 10, sort = ''}) => {
    const params = new URLSearchParams({
        name: name ?? '',
        employeeId: employeeId ?? '',
        page: page ?? 0,
        size: size ?? 10,
        sort: sort ?? ''
    });
    return api.get("/api/admin-orders?" + params);
};

export const getAdminOrder = ({id}) => {
    return api.get("/api/admin-orders/" + id);
}

export const saveAdminOrder = ({adminOrder}) => {
    return api.post("/api/admin-orders", adminOrder);
};
