import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ChangedInfo } from '../AdminOrders/list';
import { AdminOrderTypes } from '../../../constants/enum-types';

export function EmployeeHistory({ adminOrders }) {

    return <>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width={150}>Transaction Date</TableCell>
                        <TableCell width={140}>Effective Date</TableCell>
                        <TableCell width={150}>Transaction</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {adminOrders?.map((order) => {
                        return (
                            <TableRow hover tabIndex={-1} key={order.id}>
                                <TableCell>
                                    <Typography>{order.createDate}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{order.effectiveDate}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{AdminOrderTypes[order.type]}</Typography>
                                </TableCell>
                                <TableCell style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    borderStyle: "border-box"
                                }}>
                                    <ChangedInfo order={order}/>
                                </TableCell>
                            </TableRow>
                        );
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
