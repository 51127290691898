import React from "react";

import Dashboard from "../../components/dashboard";
import BranchList from "./list";
import Branch from "./branch";

export default function Branches({isForm = false}) {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Dashboard title="Branches" hasUser={() => !(!user)}>
            {isForm ?
                <Branch />
                :
                <BranchList />
            }
        </Dashboard>
    );
}
