import React, { useLayoutEffect, useState } from "react";
import moment from 'moment';

import Typography from '@mui/material/Typography';

import Dashboard from "../components/dashboard";
import api from '../services/api';
import Grid from '@mui/material/Grid';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export default function Home() {
    const user = JSON.parse(localStorage.getItem("user"));
    const hour = moment().hour();
    const [stats, setStats] = useState();

    useLayoutEffect(() => {
        api.get('/api/dashboard-stats').then(res => setStats(res.data));
    }, []);

    return (
        <Dashboard title="Dashboard">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h2">
                        Good {hour < 12 ? 'morning' : (hour < 18 ? 'afternoon' : 'evening')}, {user.firstName} {user.lastName}.
                    </Typography>
                    <br/>
                    <Link to={'/transactions'}>Click here</Link> to view the transactions.
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    {stats && (
                        <div style={{ display: 'inline-block' }}>
                            <PieChart
                                series={[
                                    {
                                        innerRadius: 70,
                                        data: [
                                            {
                                                id: 0,
                                                value: stats.active,
                                                label: `Active Staffs (${stats.active})`,
                                                color: 'rgb(2, 178, 175)'
                                            },
                                            {
                                                id: 1,
                                                value: stats.exit,
                                                label: `Exit Staffs (${stats.exit})`,
                                                color: 'rgb(114, 204, 255)'
                                            },
                                            {
                                                id: 2,
                                                value: stats.indefinite_leave,
                                                label: `Indefinite Leave (${stats.indefinite_leave})`,
                                                color: 'rgb(144, 1, 203)'
                                            },
                                        ],
                                        valueFormatter: (_) => '',
                                    },
                                ]}
                                width={550}
                                height={200}
                            >
                                <PieCenterLabel>No. of Staffs</PieCenterLabel>
                            </PieChart>
                        </div>
                    )}
                </Grid>
            </Grid>
        </Dashboard>
    );
}

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
}));

function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
        </StyledText>
    );
}
