import api from "./api";

export const getDocumentsSummary = ({name = '', page = 0, size = 10, sort = ''}) => {
    const params = new URLSearchParams({
        name: name ?? '',
        page: page ?? 0,
        size: size ?? 10,
        sort: sort ?? ''
    });
    return api.get(`/api/employment-documents?${params}`);
};

export const getDocumentsForEmployee = (employeeId) => {
    return api.get(`/api/employment-documents/employees/${employeeId}`);
};

export const uploadDocument = (form) => {
    return api.post("/api/employment-documents", form, {
        headers: {
            "Content-type": "multipart/form-data",
        },
    });
};

export const generatePublicLink = (id) => {
    return api.post(`/api/employment-documents/${id}/public-link`);
};
