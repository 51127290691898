import React, { useLayoutEffect, useState } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ButtonGroup from '@mui/material/ButtonGroup';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
    saveEmployment,
    generateIdNo,
} from '../../../services/employees';
import { mergeJSON } from '../../../helpers/json-utils';

const emptyData = {
    id: '',
    branchDataList: [],
    departmentDataList: [],
    managedBranchDataList: [],
    managedAreaDataList: [],
    managedRegionDataList: [],
    managedDivisionDataList: [],
    managedDepartmentDataList: [],
    positionData: null,
    positionAlias: '',
    type: '',
    idNo: '',
    status: '',
    startDate: '',
    startDateMoment: null,
    endDate: '',
    endDateMoment: null,
    salary: parseFloat(0).toFixed(2),
    ecola: parseFloat(0).toFixed(2),
};
const types = [
    'REGULAR',
    'PROBATIONARY'
];

const dataListFieldNames = [
    'branchDataList',
    'departmentDataList',
    'managedAreaDataList',
    'managedBranchDataList',
    'managedDepartmentDataList',
    'managedDivisionDataList',
    'managedRegionDataList',
];

/**
 * @param {{
 *   data: any,
 *   branches: any[],
 *   areas: any[],
 *   regions: any[],
 *   divisions: any[],
 *   positions: any[],
 *   departments: any[],
 *   popUpMessage: any[],
 *   setLoading: (boolean) => void,
 *   onChange: (any) => void,
 *   onEmploymentInfoChange: (any) => void,
 *   readonly: boolean,
 *   positionVisible: boolean,
 *   officeAssignmentVisible: boolean,
 *   employmentInfoVisible: boolean,
 *   idNoVisible: boolean,
 *   startDateVisible: boolean,
 * }} props
 */
export default function ContractDetailsV2(props) {
    const {data, branches, areas, regions, divisions, positions, departments, popUpMessage, setLoading, onChange} = props;

    const [employment, setEmployment] = useState(emptyData);
    const [readOnly] = useState(props.readonly ?? false);
    const [positionType, setPositionType] = useState(null);
    const [officeType, setOfficeType] = useState(null);

    useLayoutEffect(() => {
        if (!data) return;

        const branchDataList = data.branchDataList ? data.branchDataList: [];
        setEmployment(mergeJSON(emptyData, {
            ...data,
            startDateMoment: data?.startDate ? moment(data?.startDate, 'MM/DD/YYYY') : null,
            endDateMoment: data?.endDate ? moment(data?.endDate, 'MM/DD/YYYY') : null,
            salary: parseFloat(data.salary || 0).toFixed(2),
            ecola: parseFloat(data.ecola || 0).toFixed(2),
            branchDataList: branchDataList,
        }));
        setPositionType(data.positionData?.type);
        setOfficeType(data.positionData?.officeType);
    }, [data]);

    const updateField = (fieldName, value, type) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        if ("float" === type) {
            dirty[fieldName] = parseFloat(value).toFixed(2);
        }
        if (dataListFieldNames.includes(fieldName) && value?.length > 0) {
            dataListFieldNames.forEach(fName => {
                if (fName !== fieldName) {
                    dirty[fName] = [];
                }
            });

            dirty[fieldName] = value.length ? [value.pop()] : [];
        }
        setEmployment(b => {
            const updated = { ...b, ...dirty };
            props.onEmploymentInfoChange?.(updated);
            return updated;
        });
    };

    const save = async () => {
        setLoading(true);
        await saveEmployment({
            employment: employment
        }).then((res) => {
            setLoading(false);
            onChange(res.data);
            popUpMessage(true, 'Successfully saved contract details.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const clickGenerateIdNo = async () => {
        setLoading(true);
        await generateIdNo().then((res) => {
            setLoading(false);
            updateField('idNo', res.data);
            setEmployment(b => ({
                ...b,
                ...{ idNo: res.data },
            }));
            props.onEmploymentInfoChange?.(employment);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box component="form">
                <Grid container spacing={3}>
                    {props.positionVisible !== false && <>
                        <Grid item sm={12}>
                            <Autocomplete
                                readOnly={readOnly}
                                options={positions}
                                getOptionLabel={(option) => option.name}
                                value={employment.positionData}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                        required
                                        variant="standard"
                                        label={"Position"} />
                                )}
                                onChange={(e, value) => {
                                    updateField("positionData", value);
                                    updateField("branchDataList", []);
                                    updateField("managedBranchDataList", []);
                                    updateField("managedAreaDataList", []);
                                    updateField("managedRegionDataList", []);
                                    updateField("managedDivisionDataList", []);
                                    updateField("salary", value?.initSalary);
                                    updateField("ecola", value?.initEcola);
                                    setPositionType(value?.type);
                                    setOfficeType(value?.officeType);
                                }} />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField InputLabelProps={{ shrink: true }}
                                       InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                       id="positionAlias"
                                       label={"Position Alias"}
                                       value={employment.positionAlias}
                                       onChange={(e) => updateField('positionAlias', e.target.value)}
                                       fullWidth
                                       variant="standard" />
                        </Grid>
                    </>}
                    {props.officeAssignmentVisible !== false && <>
                        {positionType === 'DIVISION_MANAGER' &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly || employment.managedRegionDataList?.length > 0
                                        || employment.managedAreaDataList?.length > 0 || employment.managedBranchDataList?.length > 0}
                                    multiple
                                    options={divisions}
                                    getOptionLabel={(option) => option.name}
                                    value={employment.managedDivisionDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps,
                                                disableUnderline: (readOnly || employment.managedRegionDataList?.length > 0
                                                    || employment.managedAreaDataList?.length > 0 || employment.managedBranchDataList?.length > 0)
                                            }}
                                            variant="standard"
                                            label={"Managed Division"} />
                                    )}
                                    onChange={(e, value) => updateField("managedDivisionDataList", value)}
                                />
                            </Grid>
                        }
                        {positionType === 'REGION_MANAGER' &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly || employment.managedDivisionDataList?.length > 0
                                        || employment.managedAreaDataList?.length > 0 || employment.managedBranchDataList?.length > 0}
                                    multiple
                                    options={regions}
                                    getOptionLabel={(option) => option.name}
                                    value={employment.managedRegionDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps,
                                                disableUnderline: (readOnly || employment.managedDivisionDataList?.length > 0
                                                    || employment.managedAreaDataList?.length > 0 || employment.managedBranchDataList?.length > 0)
                                            }}
                                            variant="standard"
                                            label={"Managed Region"} />
                                    )}
                                    onChange={(e, value) => updateField("managedRegionDataList", value)}
                                />
                            </Grid>
                        }
                        {positionType === 'AREA_MANAGER' &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly || employment.managedDivisionDataList?.length > 0
                                        || employment.managedRegionDataList?.length > 0 || employment.managedBranchDataList?.length > 0}
                                    multiple
                                    options={areas}
                                    getOptionLabel={(option) => option.name}
                                    value={employment.managedAreaDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps,
                                                disableUnderline: (readOnly || employment.managedDivisionDataList?.length > 0
                                                    || employment.managedRegionDataList?.length > 0 || employment.managedBranchDataList?.length > 0)
                                            }}
                                            variant="standard"
                                            label={"Managed Area"} />
                                    )}
                                    onChange={(e, value) => updateField("managedAreaDataList", value)}
                                />
                            </Grid>
                        }
                        {positionType === 'BRANCH_MANAGER' &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly || employment.managedDivisionDataList?.length > 0
                                        || employment.managedRegionDataList?.length > 0 || employment.managedAreaDataList?.length > 0}
                                    multiple
                                    options={branches}
                                    getOptionLabel={(option) => option.code + ' - ' +option.name}
                                    value={employment.managedBranchDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps,
                                                disableUnderline: (readOnly || employment.managedDivisionDataList?.length > 0
                                                    || employment.managedRegionDataList?.length > 0 || employment.managedAreaDataList?.length > 0)
                                            }}
                                            variant="standard"
                                            label={"Managed Branch"} />
                                    )}
                                    onChange={(e, value) => updateField("managedBranchDataList", value)}
                                />
                            </Grid>
                        }
                        {positionType === 'DEPARTMENT_MANAGER' &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly}
                                    multiple
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    value={employment.managedDepartmentDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                            variant="standard"
                                            label={"Managed Department"} />
                                    )}
                                    onChange={(e, value) => updateField("managedDepartmentDataList", value)} />
                            </Grid>
                        }
                        {!positionType && (!officeType || officeType === 'OPERATION') &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly}
                                    multiple
                                    options={branches}
                                    getOptionLabel={(option) => option.code + ' - ' +option.name}
                                    value={employment.branchDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                            variant="standard"
                                            label={"Branch"} />
                                    )}
                                    onChange={(e, value) => updateField("branchDataList", value)} />
                            </Grid>
                        }
                        {!positionType && officeType === 'ADMIN' &&
                            <Grid item sm={12}>
                                <Autocomplete
                                    readOnly={readOnly}
                                    multiple
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    value={employment.departmentDataList}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{...params.InputProps, disableUnderline: readOnly}}
                                            variant="standard"
                                            label={"Department"} />
                                    )}
                                    onChange={(e, value) => updateField("departmentDataList", value)} />
                            </Grid>
                        }
                    </>}

                    {props.employmentInfoVisible !== false && <>
                        <Grid item sm={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel required shrink={true}>Employment Type</InputLabel>
                                <Select readOnly={readOnly}
                                        disableUnderline={readOnly}
                                        value={employment.type}
                                        style={{textAlign: 'left'}}
                                        onChange={(e) => updateField("type", e.target.value)}>
                                    <MenuItem value={''}>
                                        <em>None</em>
                                    </MenuItem>
                                    {types.map((el, idx) => {
                                        return (
                                            <MenuItem key={el} value={el}>{el}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={10}>
                        </Grid>

                        {props.idNoVisible !== false && <>
                            <Grid item sm={2}>
                                <TextField required InputLabelProps={{ shrink: true }}
                                           InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                           label={"ID Number"}
                                           value={employment.idNo}
                                           onChange={(e) => updateField("idNo", e.target.value)}
                                           fullWidth
                                           variant="standard" />
                            </Grid>
                            <Grid item sm={10}>
                                {!employment.idNo && !readOnly &&
                                    <Box sx={{ display: "flex"}}>
                                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                                            <Button startIcon={<RefreshIcon />} onClick={clickGenerateIdNo}>
                                                Generate
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                }
                            </Grid>
                        </>}

                        {props.startDateVisible !== false && <>
                            <Grid item sm={2} sx={{display: "flex", justifyContent: "flex-start"}}>
                                <DatePicker label="Start Date"
                                            readOnly={readOnly}
                                            slotProps={{
                                                textField: { variant: 'standard',
                                                    required: true,
                                                    InputLabelProps: {shrink: true},
                                                    InputProps: {disableUnderline: readOnly},
                                                    onChange: (newValue) => {
                                                        updateField('startDateMoment', newValue);
                                                        updateField('startDate', moment(newValue).format('MM/DD/YYYY'));
                                                    },
                                                },
                                            }}
                                            onChange={(newValue) => {
                                                updateField('startDateMoment', newValue);
                                                updateField('startDate', moment(newValue).format('MM/DD/YYYY'));
                                            }}
                                            value={employment.startDateMoment}
                                            format="MM/DD/YYYY"
                                            fullWidth />
                            </Grid>
                            <Grid item sm={2} sx={{display: "flex", justifyContent: "flex-start"}}>
                                <DatePicker label="End Date"
                                            readOnly={readOnly}
                                            slotProps={{
                                                textField: { variant: 'standard',
                                                    InputLabelProps: {shrink: true},
                                                    InputProps: {disableUnderline: readOnly},
                                                    onChange: (newValue) => {
                                                        updateField('endDateMoment', newValue);
                                                        updateField('endDate', moment(newValue).format('MM/DD/YYYY'));
                                                    },
                                                },
                                            }}
                                            onChange={(newValue) => {
                                                updateField('endDateMoment', newValue);
                                                updateField('endDate', moment(newValue).format('MM/DD/YYYY'));
                                            }}
                                            value={employment.endDateMoment}
                                            format="MM/DD/YYYY"
                                            fullWidth />
                            </Grid>
                            <Grid item sm={8} />
                        </>}

                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">
                                Compensation Details
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <TextField required InputLabelProps={{ shrink: true }}
                                       InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                       label="Basic Salary"
                                       type="number"
                                       value={employment.salary}
                                       inputProps={{ min: 0, maxLength: 13, step: "0.1", lang:"en-US", style: { textAlign: 'right' }}}
                                       onChange={(e) => updateField("salary", e.target.value)}
                                       fullWidth
                                       variant="standard" />
                        </Grid>
                        <Grid item sm={10}>
                        </Grid>
                        <Grid item sm={2}>
                            <TextField required InputLabelProps={{ shrink: true }}
                                       InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                       label="ECOLA"
                                       type="number"
                                       value={employment.ecola}
                                       inputProps={{ min: 0, maxLength: 13, step: "0.1", lang:"en-US", style: { textAlign: 'right' }}}
                                       onChange={(e) => updateField("ecola", e.target.value)}
                                       fullWidth
                                       variant="standard" />
                        </Grid>
                    </>}
                </Grid>
            </Box>

            {!readOnly &&
                <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                    <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                        <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Save
                        </Button>
                    </Box>
                </AppBar>
            }
        </LocalizationProvider>
    );
}