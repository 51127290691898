import React, { useEffect, useState } from "react";
import moment from 'moment';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { mergeJSON } from '../../../../helpers/json-utils';

const emptyData = {
    lastName: "",
    firstName: "",
    middleName: "",
    suffix: "",
    currentAddress: "",
    currentAddressMunicipality: "",
    currentAddressProvince: "",
    contactNumber: "",
    email: "",
    permanentAddress: "",
    permanentAddressMunicipality: "",
    permanentAddressProvince: "",
    birthDate: '',
    birthDateMoment: null,
    placeOfBirth: "",
    civilStatus: '',
    citizenship: "",
    height: "",
    weight: "",
    religion: "",
    gender: '',
    bloodType: "",
    languages: '',
    specialSkills: '',
    others: ''
}
const civilStatus = [
    "SINGLE",
    "MARRIED",
    "DIVORCED",
    "SEPARATED",
    "WIDOWED"
];
const gender = [
    "MALE",
    "FEMALE"
];

export default function PersonalData({ data, updateEmployee, readOnly }) {
    const [personalData, setPersonalData] = useState(emptyData);

    useEffect(() => {
        if (!data) return;

        setPersonalData(mergeJSON(emptyData, {
            ...data,
            birthDateMoment: data?.birthDate ? moment(data?.birthDate, 'MM/DD/YYYY') : null,
        }));
    }, [data]);

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setPersonalData(b => ({
            ...b,
            ...dirty
        }));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={3}>
                <Grid item sm={3}>
                    <TextField required
                        InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="lastName"
                        label="Last Name"
                        value={personalData.lastName}
                        onChange={(e) => updateField('lastName', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={4}>
                    <TextField required
                        InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="firstName"
                        label="First Name"
                        value={personalData.firstName}
                        onChange={(e) => updateField('firstName', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="middleName"
                        label="Middle Name"
                        value={personalData.middleName}
                        onChange={(e) => updateField('middleName', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={2}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="suffix"
                        label="Suffix Name"
                        value={personalData.suffix}
                        onChange={(e) => updateField('suffix', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="body1" align="left">
                        Current Address
                    </Typography>
                </Grid>
                <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <TextField required
                        InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="currentAddress"
                        label="Address"
                        value={personalData.currentAddress}
                        onChange={(e) => updateField('currentAddress', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="currentAddressMunicipality"
                        label="Municipality"
                        value={personalData.currentAddressMunicipality}
                        onChange={(e) => updateField('currentAddressMunicipality', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="currentAddressProvince"
                        label="Province"
                        value={personalData.currentAddressProvince}
                        onChange={(e) => updateField('currentAddressProvince', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3}>
                    <TextField required
                        InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="contactNumber"
                        label="Contact #"
                        value={personalData.contactNumber}
                        onChange={(e) => updateField('contactNumber', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="email"
                        label="Email"
                        type={"email"}
                        value={personalData.email}
                        onChange={(e) => updateField('email', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="body1" align="left">
                        Permanent Address
                    </Typography>
                </Grid>
                <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="permanentAddress"
                        label="Address"
                        value={personalData.permanentAddress}
                        onChange={(e) => updateField('permanentAddress', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="permanentAddressMunicipality"
                        label="Municipality"
                        value={personalData.permanentAddressMunicipality}
                        onChange={(e) => updateField('permanentAddressMunicipality', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="permanentAddressProvince"
                        label="Province"
                        value={personalData.permanentAddressProvince}
                        onChange={(e) => updateField('permanentAddressProvince', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={2}>
                    <DatePicker label="Date of Birth"
                        readOnly={readOnly}
                        slotProps={{
                            textField: { variant: 'standard',
                                required: true,
                                InputLabelProps: {shrink: true},
                                InputProps: {disableUnderline: readOnly},
                                onChange: (newValue) => {
                                    updateField('birthDateMoment', newValue);
                                    updateField('birthDate', moment(newValue).format('MM/DD/YYYY'));
                                },
                            },
                        }}
                        onChange={(newValue) => {
                            updateField('birthDateMoment', newValue);
                            updateField('birthDate', moment(newValue).format('MM/DD/YYYY'));
                        }}
                        maxDate={moment()}
                        value={personalData.birthDateMoment}
                        format="MM/DD/YYYY"
                        fullWidth />
                </Grid>
                <Grid item sm={4}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="placeOfBirth"
                        label="Place of Birth"
                        value={personalData.placeOfBirth}
                        onChange={(e) => updateField('placeOfBirth', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="civilStatus-label" shrink={true}>Civil Status</InputLabel>
                        <Select labelId="civilStatus-label"
                            id="civilStatus"
                            readOnly={readOnly}
                            disableUnderline={readOnly}
                            value={personalData.civilStatus}
                            onChange={(e) => updateField('civilStatus', e.target.value)}
                            onBlur={(e) => updateEmployee('personalData', personalData)}>
                            <MenuItem value={null}>
                                <em>None</em>
                            </MenuItem>
                            {civilStatus.map((cs, idx) => {
                                return (
                                    <MenuItem key={cs} value={cs}>{cs}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="citizenship"
                        label="Citizenship"
                        value={personalData.citizenship}
                        onChange={(e) => updateField('citizenship', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={2}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="height"
                        label="Height"
                        value={personalData.height}
                        onChange={(e) => updateField('height', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={2}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="weight"
                        label="Weight"
                        value={personalData.weight}
                        onChange={(e) => updateField('weight', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={4}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="religion"
                        label="Religion"
                        value={personalData.religion}
                        onChange={(e) => updateField('religion', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={2}>
                    <FormControl required variant="standard" fullWidth>
                        <InputLabel id="gender-label" shrink={true}>Gender</InputLabel>
                        <Select labelId="gender-label"
                            id="gender"
                            readOnly={readOnly}
                            disableUnderline={readOnly}
                            value={personalData.gender}
                            onChange={(e) => updateField('gender', e.target.value)}
                            onBlur={(e) => updateEmployee('personalData', personalData)}>
                            <MenuItem value={null}>
                                <em>None</em>
                            </MenuItem>
                            {gender.map((g, idx) => {
                                return (
                                    <MenuItem key={g} value={g}>{g}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="bloodType"
                        label="Blood Type"
                        value={personalData.bloodType}
                        onChange={(e) => updateField('bloodType', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="languages"
                        label="Languages or Dialects you can speak or write"
                        value={personalData.languages}
                        onChange={(e) => updateField('languages', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        multiline
                        rows={4}
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="specialSkills"
                        label="Special Skills"
                        value={personalData.specialSkills}
                        onChange={(e) => updateField('specialSkills', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        multiline
                        rows={4}
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="others"
                        label="Others"
                        value={personalData.others}
                        onChange={(e) => updateField('others', e.target.value)}
                        onBlur={(e) => updateEmployee('personalData', personalData)}
                        fullWidth
                        multiline
                        rows={4}
                        variant="standard" />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}