import React, { useLayoutEffect, useState } from "react";
import moment from 'moment';

import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { mergeJSON } from '../../../../helpers/json-utils';

const emptyData = {
    spouseName: "",
    spouseOccupation: "",
    spouseEmployer: "",
    spouseAddress: "",
    spouseAddressMunicipality: "",
    spouseAddressProvince: "",
    children: []
};
const emptyChild = {
    name: "",
    birthDate: "",
    birthDateMoment: null,
    placeOfBirth: "",
    occupation: "",
    employer: ""
};

export default function SpouseChildrenData({ data, updateEmployee, readOnly }) {
    const [spouseChildrenData, setSpouseChildrenData] = useState(emptyData);

    useLayoutEffect(() => {
        if (!data) return;

        setSpouseChildrenData(() => {
            let children = [];
            data?.children?.forEach((item) => {
                children.push({
                    ...item,
                    birthDateMoment: item?.birthDate ? moment(item?.birthDate, 'MM/DD/YYYY') : null,
                });
            });

            return mergeJSON(emptyData, {
                ...data,
                children: children,
            });
        });
    }, [data]);

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setSpouseChildrenData(b => ({
            ...b,
            ...dirty
        }));
    };

    const updateChild = (idx, fieldName, value) => {
        let children = spouseChildrenData.children;
        let child = children[idx];
        child[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        updateField("children", children);
    };

    const addChild = () => {
        let children = spouseChildrenData.children;
        children.push({
            ...emptyChild,
        });
        updateField("children", children);
    };

    const removeLastChild = () => {
        let children = spouseChildrenData.children;
        children.pop();
        updateField("children", children);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography variant="h6" align="left">
                        Spouse
                    </Typography>
                </Grid>
                <Grid item sm={6}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="spouseName"
                        label="Full Name"
                        value={spouseChildrenData.spouseName}
                        onChange={(e) => updateField('spouseName', e.target.value)}
                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="spouseOccupation"
                        label="Occupation"
                        value={spouseChildrenData.spouseOccupation}
                        onChange={(e) => updateField('spouseOccupation', e.target.value)}
                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="spouseEmployer"
                        label="Employer"
                        value={spouseChildrenData.spouseEmployer}
                        onChange={(e) => updateField('spouseEmployer', e.target.value)}
                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="body1" align="left">
                        Spouse Address
                    </Typography>
                </Grid>
                <Grid item sm={6} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="spouseAddress"
                        label="Address"
                        value={spouseChildrenData.spouseAddress}
                        onChange={(e) => updateField('spouseAddress', e.target.value)}
                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="spouseAddressMunicipality"
                        label="Municipality"
                        value={spouseChildrenData.spouseAddressMunicipality}
                        onChange={(e) => updateField('spouseAddressMunicipality', e.target.value)}
                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={3} style={{ paddingTop: 0 }}>
                    <TextField InputLabelProps={{ shrink: true }}
                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                        id="spouseAddressProvince"
                        label="Province"
                        value={spouseChildrenData.spouseAddressProvince}
                        onChange={(e) => updateField('spouseAddressProvince', e.target.value)}
                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="h6" align="left">
                        Children
                    </Typography>
                </Grid>
                {spouseChildrenData.children?.length > 0 ?
                    spouseChildrenData.children?.map((c, idx) => {
                        return (
                            <React.Fragment key={`child-${idx}`}>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left">
                                        Child { idx + 1 }
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField required InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`child-name-${idx}`}
                                        label="Full Name"
                                        value={c.name}
                                        onChange={(e) => updateChild(idx, "name", e.target.value)}
                                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                                <Grid item sm={2}>
                                    <DatePicker label="Date of Birth"
                                        readOnly={readOnly}
                                        slotProps={{
                                            textField: { variant: 'standard',
                                                required: true,
                                                InputLabelProps: {shrink: true},
                                                InputProps: {disableUnderline: readOnly},
                                                onChange: (newValue) => {
                                                    updateChild(idx, "birthDateMoment", newValue);
                                                    updateChild(idx, "birthDate", moment(newValue).format('MM/DD/YYYY'));
                                                },
                                            },
                                        }}
                                        onChange={(newValue) => {
                                            updateChild(idx, "birthDateMoment", newValue);
                                            updateChild(idx, "birthDate", moment(newValue).format('MM/DD/YYYY'));
                                        }}
                                        maxDate={moment()}
                                        value={c.birthDateMoment}
                                        format="MM/DD/YYYY"
                                        fullWidth />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`child-placeOfBirth-${idx}`}
                                        label="Place of Birth"
                                        value={c.placeOfBirth}
                                        onChange={(e) => updateChild(idx, "placeOfBirth", e.target.value)}
                                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`child-occupation-${idx}`}
                                        label="Occupation"
                                        value={c.occupation}
                                        onChange={(e) => updateChild(idx, "occupation", e.target.value)}
                                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField InputLabelProps={{ shrink: true }}
                                        InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                        id={`child-employer-${idx}`}
                                        label="Employer"
                                        value={c.employer}
                                        onChange={(e) => updateChild(idx, "employer", e.target.value)}
                                        onBlur={(e) => updateEmployee('spouseChildrenData', spouseChildrenData)}
                                        fullWidth
                                        variant="standard" />
                                </Grid>
                            </React.Fragment>
                        );
                    })
                    :
                    <Grid item sm={12}>
                        <Typography variant="subtitle1" sx={{ color:'gray', py:2, borderStyle:'dashed', borderWidth:1 }}>
                            No children added
                        </Typography>
                    </Grid>
                }
                {!readOnly &&
                    <Grid item sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button onClick={addChild} variant="contained"
                                startIcon={<AddIcon />} sx={{ mt: 3, ml: 1 }}>
                                Add
                            </Button>
                            <Button onClick={removeLastChild} variant="contained"
                                startIcon={<RemoveIcon />} sx={{ mt: 3, ml: 1 }}
                                disabled={spouseChildrenData.children?.length === 0}>
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>
        </LocalizationProvider>
    );
}