import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React from 'react';

export default function MessageDialog({ title, message, open, onClose }) {
    return <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message?.split(/\n/)?.map(m => <div>{m}</div>)}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose?.()} autoFocus>
                OK
            </Button>
        </DialogActions>
    </Dialog>;
}
