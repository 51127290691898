import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { getAllBranches, } from '../../../services/branches';
import { getAllAreas, } from '../../../services/areas';
import { getAllRegions, } from '../../../services/regions';
import { getAllDivisions, } from '../../../services/divisions';
import { getAllPositions, } from '../../../services/positions';
import {
    getEmployee,
    getEmployeeLeaveHistory,
    getEmployeeLeaveStatus,
    getEmployment,
    getLeaves,
} from '../../../services/employees';
import { savePhoto } from '../../../services/employeephotos';
import { getAttachments, } from '../../../services/attachments';
import Title from '../../../components/Title';
import EmployeeDetails from "./EmployeeDetails";
import ContractDetails from "./contract-details";
import LeaveDetails from "./leave-details";
import EmployeeAttachment from "./attachments";
import { getAllDepartments } from '../../../services/depratments';
import { EmployeeHistory } from './history';
import { getAdminOrders } from '../../../services/admin-orders';
import TabbedPane from '../../../components/TabbedPane';
import MessageDialog from '../../../components/MessageDialog';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default function Employee() {
    const { id: viewIdParam } = useParams();
    const [id] = useState(viewIdParam && viewIdParam !== 'add-new' ? viewIdParam : undefined);
    const navigate = useNavigate();
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState([]);
    const [regions, setRegions] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [employee, setEmployee] = useState({});
    const [employment, setEmployment] = useState({});
    const [leaves, setLeaves] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [usedCredits, setUsedCredits] = useState([]);
    const [leaveHistory, setLeaveHistory] = useState([]);
    const [adminOrders, setAdminOrders] = useState([]);

    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) {
            return;
        }

        setLoading(true);
        Promise.all([
            getEmployee({ id: id }),
            getEmployment({ id: id }),
            getLeaves({ id: id }),
            getAttachments({ empId: id }),
            getEmployeeLeaveStatus(id),
            getEmployeeLeaveHistory({ id }),
            getAdminOrders({ employeeId: id, sort: 'createDate,desc' }),
        ]).then(([employeeRes, employmentRes, leavesRes, attachmentsRes, usedCreditsRes, leaveHistoryRes, adminOrdersRes]) => {
            setEmployee(employeeRes.data);
            setEmployment(employmentRes.data);
            setLeaves(leavesRes.data);
            setAttachments(attachmentsRes.data);
            setUsedCredits(usedCreditsRes.data?.reduce((map, status) => ({...map, [status.type]: status.used}), {}))
            setLeaveHistory(leaveHistoryRes.data?.content);
            setAdminOrders(adminOrdersRes.data?.content);
            setLoading(false);
        })
    }, [id]);

    useLayoutEffect(() => {
        getAllBranches({ sort: 'code' }).then((res) => setBranches(res.data));
        getAllAreas({ sort: 'name' }).then((res) => setAreas(res.data));
        getAllRegions({ sort: 'name' }).then((res) => setRegions(res.data));
        getAllDivisions({ sort: 'name' }).then((res) => setDivisions(res.data));
        getAllPositions({ sort: 'name' }).then((res) => setPositions(res.data));
        getAllDepartments({ sort: 'name' }).then(res => setDepartments(res.data));
    }, []);

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const filesize = ((file.size / 1024) / 1024).toFixed(4);
        if (filesize > 5) {
            popUpMessage(false, "Photo must be less than 5MB");
            event.target.value = "";
            return;
        }

        let data = new FormData();
        data.append('photo', file, file.name);
        data.append('id', employee.id);

        setLoading(true);
        savePhoto(data).then((res) => {
            setLoading(false);
            popUpMessage(true, 'Successfully uploaded employee photo.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const handleUpdateEmployeeDetails = (e) => {
        setEmployee(e);
        if (!employment || !employment.id) {
            const dirty = {
                id: e.id,
            };
            setEmployment(b => ({
                ...b,
                ...dirty
            }));
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                        <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                          <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                    <Title>Employee Details</Title>
                </Grid>
                <Grid item xs={1} container justifyContent="flex-end" alignItems="center">
                    <Box sx={{ mr: 2 }}>
                        <Typography component="h1" variant="h2" color="red" gutterBottom align="left" fontWeight='fontWeightMedium'>
                            {employment?.idNo}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {employee.id &&
                            <IconButton variant="contained" component="label">
                                {employee.hasPhoto ?
                                    <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/image/${employee.id}?rnd=${new Date().getTime()}`} sx={{ width: 120, height: 120 }} />
                                    :
                                    <Avatar sx={{ width: 120, height: 120 }}>Upload Photo</Avatar>
                                }
                                <input type="file" hidden onChange={handleUploadClick} />
                            </IconButton>
                        }
                    </Box>
                </Grid>
            </Grid>

            <TabbedPane sx={{ mt: 5, minHeight: '60vh', marginTop: '0' }}>
                <TabbedPane.Tab title="Personal Details">
                    <EmployeeDetails data={employee} popUpMessage={popUpMessage} setLoading={setLoading} onChange={(e) => handleUpdateEmployeeDetails(e)} />
                </TabbedPane.Tab>
                <TabbedPane.Tab title="Contract Details" disabled={!employee.id} >
                    <ContractDetails data={employment} branches={branches} areas={areas} regions={regions} divisions={divisions} departments={departments}
                                     positions={positions} popUpMessage={popUpMessage} setLoading={setLoading} onChange={(e) => setEmployment(e)} />
                </TabbedPane.Tab>
                <TabbedPane.Tab title="Leave Details" disabled={!employee.id} >
                    <LeaveDetails data={leaves} hasLeaveInfo={employee.hasLeaveInfo} gender={employee.personalData?.gender}
                                  usedCredits={usedCredits} leaveHistory={leaveHistory}
                                  popUpMessage={popUpMessage} setLoading={setLoading} onChange={(e) => setEmployment(e)} />
                </TabbedPane.Tab>
                <TabbedPane.Tab title="Attachments" disabled={!employee.id} >
                    <EmployeeAttachment data={attachments} empId={employee.id} popUpMessage={popUpMessage} setLoading={setLoading} />
                </TabbedPane.Tab>
                <TabbedPane.Tab title="History" disabled={!employee.id} >
                    <EmployeeHistory adminOrders={adminOrders}/>
                </TabbedPane.Tab>
            </TabbedPane>

            <MessageDialog
                open={openMessage}
                title={success ? 'Success' : 'Error'}
                message={message}
                onClose={() => setOpenMessage(!openMessage)}
            />

            <LoadingSpinner open={loading} />

        </React.Fragment>
    );
}