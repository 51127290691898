import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useRef, useState } from 'react';
import { getAllEmployees } from '../services/employees';
import { mergeJSON } from '../helpers/json-utils';

/**
 * @param {boolean} readOnly
 * @param {any} value
 * @param {(any) => void} onChange
 * @param showRegularEmployeeIndicator
 * @param {any} inputProps
 * @return {Element}
 * @constructor
 */
export default function EmployeeNameLookup({ readOnly, value, onChange, showRegularEmployeeIndicator = false, inputProps = {} }) {

    const [employees, setEmployees] = useState([]);

    if (showRegularEmployeeIndicator && value) {
        inputProps = mergeJSON(inputProps, {
            style: {
                color: value?.employmentInfoData?.noOfMonths >= 6 ? 'darkred' : ''
            }
        });
    }

    const getData = (name) => {
        getAllEmployees({
            name: name
        }).then((res) => {
            setEmployees(res.data);
        });
    };

    const onInputEmployeeChange = (event, name) => {
        name = name?.toUpperCase();
        if (name && name.length >= 3) {
            getData(name);
        } else {
            setEmployees([]);
        }
    };

    return <Autocomplete
        readOnly={readOnly}
        getOptionLabel={(option) => option?.personalData ? option.personalData.lastName + ', ' + option.personalData.firstName : ''}
        options={employees}
        value={value}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onInputChange={onInputEmployeeChange}
        renderInput={(params) => (
            <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps, disableUnderline: readOnly, ...inputProps }}
                required
                variant="standard"
                label="Employee Name"/>
        )}
        onChange={(e, value) => onChange?.(value)}/>
}
