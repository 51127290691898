import api from "./api";

export const getBenefitsSummary = ({name = '', employeeId = '', page = 0, size = 10, sort = ''}) => {
    const params = new URLSearchParams({
        name: name ?? '',
        employeeId: employeeId ?? '',
        page: page ?? 0,
        size: size ?? 10,
        sort: sort ?? ''
    });
    return api.get(`/api/employee-benefits?${params}`);
};

export const getBenefitTypes = () => {
    return api.get(`/api/employee-benefits/types`);
};

export const addBenefit = (benefitForm) => {
    return api.post(`/api/employee-benefits`, benefitForm);
};
