import React, { useLayoutEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import AppBar from '@mui/material/AppBar';

import Title from '../../components/Title';
import { getPosition, savePosition } from "../../services/positions";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const emptyData = {
    id: '',
    name: '',
    shortName: '',
    sortOrder: '',
    initSalary: 0.0,
    initEcola: 0.0,
    type: '',
    officeType: '',
};

export default function Branch() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [originalData, setOriginalData] = useState(emptyData);
    const [position, setPosition] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(true);

    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) {
            setReadOnly(false);
            return;
        }

        setLoading(true);
        getPosition({
            id: id
        }).then((res) => {
            setPosition(res.data);
            setOriginalData(res.data);
            setReadOnly(true);
            setLoading(false);
        });
    }, [id]);

    const save = () => {
        setLoading(true);
        savePosition({
            position: position
        }).then((res) => {
            setLoading(false);
            setPosition(res.data);
            popUpMessage(true, 'Successfully saved position details.');
            setReadOnly(true);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        if (fieldName === 'type' && !!value) {
            dirty.officeType = null;
        }
        setPosition(b => ({
            ...b,
            ...dirty
        }));
    };

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const cancel = async () => {
        setPosition(originalData);
        setReadOnly(true);
    }

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Title>Position Details</Title>
            <Box sx={{ mt: 5, minHeight: '60vh' }} component="form" noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="name"
                            label="Name"
                            value={position.name}
                            onChange={(e) => updateField('name', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="shortName"
                            label="Short Name"
                            value={position.shortName}
                            onChange={(e) => updateField('shortName', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={6} />

                    <Grid item sm={2}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="sortOrder"
                            label="Sort Order"
                            value={position.sortOrder}
                            type="number"
                            inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("sortOrder", parseInt(e.target.value))}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={10} />

                    <Grid item sm={2}>
                        <TextField required InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Initial Basic Salary"
                            type="number"
                            value={position.initSalary}
                            inputProps={{ min: 0, maxLength: 13, step: "0.1", lang:"en-US", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("initSalary", e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={10} />

                    <Grid item sm={2}>
                        <TextField required InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Initial ECOLA"
                            type="number"
                            value={position.initEcola}
                            inputProps={{ min: 0, maxLength: 13, step: "0.1", lang:"en-US", style: { textAlign: 'right' }}}
                            onChange={(e) => updateField("initEcola", e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={10} />

                    <Grid item sm={5}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="type-label" shrink={true}>Managed Office</InputLabel>
                            <Select labelId="type-label"
                                    id="type"
                                    required
                                    fullWidth
                                    displayEmpty
                                    readOnly={readOnly}
                                    disableUnderline={readOnly}
                                    value={position.type}
                                    onChange={(e) => updateField('type', e.target.value)}
                                    onBlur={(e) => updateField('type', e.target.value)}>
                                <MenuItem value={null}>None</MenuItem>
                                <MenuItem value="AREA_MANAGER">Area</MenuItem>
                                <MenuItem value="BRANCH_MANAGER">Branch</MenuItem>
                                <MenuItem value="DEPARTMENT_MANAGER">Department</MenuItem>
                                <MenuItem value="DIVISION_MANAGER">Division</MenuItem>
                                <MenuItem value="REGION_MANAGER">Region</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={7} />

                    {!position.type && <React.Fragment>
                        <Grid item sm={5}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="officeType-label" shrink={true}>Office Assignment *</InputLabel>
                                <Select labelId="officeType-label"
                                        id="type"
                                        required
                                        fullWidth
                                        readOnly={readOnly}
                                        disableUnderline={readOnly}
                                        value={position.officeType}
                                        style={{textAlign: 'left'}}
                                        onChange={(e) => updateField('officeType', e.target.value)}
                                        onBlur={(e) => updateField('officeType', e.target.value)}>
                                    <MenuItem key="ADMIN" value="ADMIN">Department</MenuItem>
                                    <MenuItem key="OPERATION" value="OPERATION">Branch</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </Box>
            <Dialog open={openMessage}>
                <DialogTitle id="alert-dialog-title">
                    {success ? "Success": "Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMessage(!openMessage)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                    {!readOnly ?
                        <React.Fragment>
                            <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            {!!id &&
                                <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                    Cancel
                                </Button>
                            }
                        </React.Fragment>
                    :
                        <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Edit
                        </Button>
                    }
                </Box>
            </AppBar>
        </Paper>
    );
}
